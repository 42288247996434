<template>
  <footer>
      <p>Copyright &copy; {{ year }} </p>
  </footer>
</template>

<script>
import { computed } from 'vue'

export default {
  setup () {
    const year = computed(() => new Date().getFullYear())
    return { year }
  }
}
</script>

<style scoped>
footer {
padding-top: 5rem;
padding-bottom: 2rem;
}
</style>
