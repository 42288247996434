<template>
  <div class="home">
    <AppServerList msg="Welcome to Whereisdi"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppServerList from '@/components/AppServerList.vue' // @ is an alias to /src

export default defineComponent({
  name: 'HomeView',
  components: {
    AppServerList
  }
})
</script>
