<template>
        <div class="item-list">
            <!-- <h1>{{ msg }}</h1> -->
            <div id="pinned-items" v-if="pinnedItems.length > 0">
              <div class="spacer"></div>
              <ul>
                  <li v-for="item in pinnedItems" :key="item.id" class="item">
                      <p class="server-name-active"><strong>{{ item.server.name }}</strong></p>
                      <div class="item-location"><p :class="{ italic: item.location.en_us === 'Inactive' }">{{ item.location.en_us }}</p></div>
                      <span class="updated-time"><i>{{ formatDate(item.date_updated) }}</i></span>
                      <button class="pin-button" @click="unpinItem(item)">&#9733;</button> <!-- Filled star -->
                  </li>
              </ul>
            </div>
            <div id="active-items">
                <h3>Active</h3>
                <ul>
                    <li v-for="item in activeItems" :key="item.id" class="item">
                        <p class="server-name-active"><strong>{{ item.server.name }}</strong></p>
                        <div class="item-location"><p>{{ item.location ? item.location.en_us : "Unknown" }}</p></div>
                        <span class="updated-time"><i>{{ formatDate(item.date_updated) }}</i></span>
                        <button class="pin-button" @click="pinItem(item)">&#9734;</button> <!-- Empty star -->
                    </li>
                </ul>
            </div>
            <div id="inactive-items">
                <h3>Inactive</h3>
                <ul class="inactive-items">
                    <li v-for="item in inactiveItems" :key="item.id" class="inactive-items">
                        <p><strong>{{ item.server.name }}</strong>
                        <span class="updated-time"><i>&nbsp;&nbsp;Last updated: {{ formatDate(item.date_updated) }}</i></span>
                        </p>
                        <button class="pin-button" @click="pinItem(item)">&#9734;</button> <!-- Empty star -->
                    </li>
                </ul>
            </div>
        </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { formatDistanceToNow, isAfter, addSeconds } from 'date-fns'
import Cookies from 'js-cookie'

interface Item {
  id: number;
  server: { name: string };
  // eslint-disable-next-line camelcase
  location: { en_us: string; max_age: number };
  // eslint-disable-next-line camelcase
  date_updated: string;
}

export default defineComponent({
  name: 'AppItemList',
  props: {
    msg: String
  },
  setup () {
    const items = ref<Item[]>([])
    const activeItems = ref<Item[]>([])
    const inactiveItems = ref<Item[]>([])
    const pinnedItems = ref<Item[]>([])
    const pinOrder = ref<number[]>([])

    onMounted(async () => {
      // Load the pinned items and pin order from the cookies
      const savedPinnedItems = Cookies.get('pinnedItems')
      const savedPinOrder = Cookies.get('pinOrder')
      if (savedPinnedItems) {
        pinnedItems.value = JSON.parse(savedPinnedItems)
      }
      if (savedPinOrder) {
        pinOrder.value = JSON.parse(savedPinOrder)
      }

      const fetchData = async () => {
        const response = await fetch('https://api.whereisdi.com/items/di?fields=*.*', {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`
          }
        })
        const data = await response.json()
        const newItems = data.data
          .filter((item: { location: any }) => item.location)
          .sort((a: { id: number }, b: { id: number }) => a.id - b.id)

        const pinnedItemIds = pinnedItems.value.map(item => item.id)

        // Update pinned items
        pinnedItems.value = newItems.filter((item: Item) => pinnedItemIds.includes(item.id))

        // Sort pinned items according to pin order
        pinnedItems.value.sort((a, b) => pinOrder.value.indexOf(a.id) - pinOrder.value.indexOf(b.id))

        // Split items into active and inactive
        activeItems.value = []
        inactiveItems.value = []

        newItems.forEach((item: Item) => {
          const date = new Date(item.date_updated)
          const isActive = isAfter(date, addSeconds(new Date(), item.location.max_age * -1 || 0))

          // Check if the item is inactive and, if so, set its location.en_us property to 'Inactive'
          if (!isActive) {
            item.location.en_us = 'Inactive'
          }

          // Check if the item is not pinned before adding it to active or inactive items
          if (!pinnedItemIds.includes(item.id)) {
            if (isActive) {
              activeItems.value.push(item)
            } else {
              inactiveItems.value.push(item)
            }
          }
        })
      }

      // Fetch data immediately on mount
      await fetchData()

      // Then fetch data every 20 seconds
      setInterval(fetchData, 20000)
    })

    const pinItem = (item: Item) => {
      // Check if the item is inactive
      const date = new Date(item.date_updated)
      const isInactive = isAfter(addSeconds(new Date(), item.location.max_age * -1 || 0), date)

      if (isInactive) {
        // Change the en_us data to "No data"
        item.location.en_us = 'Inactive'
      }

      // Add the item to pinnedItems
      pinnedItems.value.push(item)

      // Add the item's ID to pinOrder
      pinOrder.value.push(item.id)

      // Remove the item from activeItems or inactiveItems
      activeItems.value = activeItems.value.filter(i => i.id !== item.id)
      inactiveItems.value = inactiveItems.value.filter(i => i.id !== item.id)

      // Save the pinned items to a cookie
      Cookies.set('pinnedItems', JSON.stringify(pinnedItems.value), { sameSite: 'strict' })
      Cookies.set('pinOrder', JSON.stringify(pinOrder.value), { sameSite: 'strict' })
    }

    const unpinItem = (item: Item) => {
      // Remove the item from pinnedItems
      pinnedItems.value = pinnedItems.value.filter(i => i.id !== item.id)

      // Check if the item is active or inactive and add it back to the appropriate list
      const date = new Date(item.date_updated)
      if (isAfter(date, addSeconds(new Date(), item.location.max_age * -1 || 0))) {
        activeItems.value.push(item)
      } else {
        inactiveItems.value.push(item)
      }

      // Sort the activeItems and inactiveItems lists by ID
      activeItems.value.sort((a, b) => a.id - b.id)
      inactiveItems.value.sort((a, b) => a.id - b.id)

      // Remove the item's ID from pinOrder
      pinOrder.value = pinOrder.value.filter(id => id !== item.id)

      // Save the pinned items to a cookie
      Cookies.set('pinnedItems', JSON.stringify(pinnedItems.value), { sameSite: 'strict' })
      Cookies.set('pinOrder', JSON.stringify(pinOrder.value), { sameSite: 'strict' })
    }

    return { items, activeItems, inactiveItems, pinnedItems, pinItem, unpinItem }
  },
  methods: {
    formatDate (date: string | number | Date | null) {
      if (date === null) {
        return 'Unknown'
      }

      return formatDistanceToNow(new Date(date), { addSuffix: true })
    }
  }
})
</script>

<style scoped lang="scss">
    .spacer {
      height: 2rem;
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .updated-time {
      margin-top: auto;
      margin-bottom: 0.75rem;
    }
    .italic {
      font-style: italic;
    }
    .item-location {
        font-size: 1rem;
        align-self: flex-start;
        text-align: left;
        margin-top: 0;
    }
    .updated-time {
        font-size: 0.8rem;
    }
    li {
        position: relative;
    }
    .pin-button {
        position: absolute;
        top: 0.5rem;
        right: 1rem;
        background: none;
        border: none;
        color: #000; // Change this to the color you want for the star
        cursor: pointer;
        font-size: 1.5em; // Adjust this to change the size of the star
        padding: 0;
    }
    .pin-button:focus {
        outline: none;
    }
    .server-name-active {
        margin-bottom: 5px;
    }
    h3 {
            margin: 40px 0 0;
    }
    ul {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 3rem;
        row-gap: 1rem;
        justify-items: center;
        align-items: center;
        padding: 0;
        list-style-type: none;
        margin: 0 auto;
        width: 40%; // Adjust this to control the space on the left and right
    }
    ul.inactive-items {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: stretch;
        padding: 0;
        list-style-type: none;
        margin: 0 auto;

        li {
            display: flex;
            align-items: flex-start;
            padding-left: 20px;
            padding-top: 0;
            padding-bottom: 0;
            width: 70%; // Adjust this to control the horizontal size
            height: 50px; // Adjust this to control the vertical size
            margin-bottom: -20px; // Adjust this to control the space between items
        }
    }
    li {
        text-align: left;
        width: 16rem;
        height: 8rem;
        margin: 10px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 0;
        padding-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        background-color: #fff;
    }
    a {
            color: #42b983;
    }
    @media (max-width: 4000px) {
        ul {
            grid-template-columns: repeat(5, 1fr);
            width: 50%;
            row-gap: 0;
            //grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
            li {
                height: 9rem;
                width: 90%;
                margin-bottom: 0px;
            }
        }
        ul.inactive-items {
            width: 50%;
            row-gap: 0;

            li {
                width: 100%;
                margin-bottom: 0px;
            }
        }
    }
    @media (max-width: 2500px) {
        ul {
            grid-template-columns: repeat(4, 1fr);
            width: 60%;
            row-gap: 0;
            //grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
            li {
                height: 9rem;
                width: 90%;
                margin-bottom: 0px;
            }
        }
        ul.inactive-items {
            width: 50%;
            row-gap: 0;

            li {
                width: 100%;
                margin-bottom: 0px;
            }
        }
    }
    @media (max-width: 2000px) {
        ul {
            grid-template-columns: repeat(4, 1fr);
            width: 70%;
            row-gap: 0;
            //grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
            li {
                height: 9rem;
                width: 90%;
                margin-bottom: 0px;
            }
        }
        ul.inactive-items {
            width: 50%;
            row-gap: 0;

            li {
                width: 100%;
                margin-bottom: 0px;
            }
        }
    }
    @media (max-width: 1700px) {
        ul {
            grid-template-columns: repeat(4, 1fr);
            width: 80%;
            row-gap: 0;
            //grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
            li {
                height: 9rem;
                width: 90%;
                margin-bottom: 0px;
            }
        }
        ul.inactive-items {
            width: 50%;
            row-gap: 0;

            li {
                width: 100%;
                margin-bottom: 0px;
            }
        }
    }
    @media (max-width: 1079px) {
        ul {
            grid-template-columns: repeat(2, 1fr);
            width: 90%;
            row-gap: 0;
            //grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
            li {
                width: 90%;
                margin-bottom: 0px;
            }
        }
        ul.inactive-items {
            width: 100%;
            row-gap: 0;

            li {
                width: 100%;
                margin-bottom: 0px;
            }
        }
    }
    @media (max-width: 1200px) {
        ul {
            grid-template-columns: repeat(3, 1fr);
            width: 90%;
            row-gap: 0;
            //grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
            li {
                width: 90%;
                margin-bottom: 0px;
            }
        }
        ul.inactive-items {
            width: 100%;
            row-gap: 0;

            li {
                width: 100%;
                margin-bottom: 0px;
            }
        }
    }
    @media (max-width: 768px) {
        .server-name-active {
            font-size: 0.95rem;
            margin-bottom: 0px;
        }
        ul {
            grid-template-columns: repeat(1, 1fr);
            width: 100%;
            row-gap: 0;
            li {
                font-size: small;
                height: 7.5rem;
                width: 80%;
                margin-bottom: 0px;
            }
        }
        ul.inactive-items {
            width: 100%;
            row-gap: 0;

            li {
                width: 100%;
                margin-bottom: 0px;
                p {
                  margin-top: 1rem;
                }
            }
        }
    }
</style>
