<template>
    <header>
      <router-link to="/" style="display: flex; align-items: center;">
        <img alt="Whereisdi logo" src="../assets/whereisdi.png" height="60">
      </router-link>
      <h1>Whereisdi</h1>
      <nav>
        <ul>
            <!-- <li><router-link to="/">Home</router-link></li> -->
            <!-- <li><router-link to="/about">About</router-link></li> -->
        </ul>
      </nav>
    </header>
</template>

<style lang="scss">
  header {
    display: flex;
    height: 4rem;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 0.0rem 1rem;
    background-color: #9AABB9; // Add this line

    p {
      margin-left: 1rem;
    }
  }

  h1 {
    font-size: 1.5em;
  }

  nav {
    margin-left: auto;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    ul li {
      display: inline-flex;
      margin-left: 1rem;
    }
  }
</style>
